<h2 mat-dialog-title>Refund Order to Wallet</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="wallet_refund_dialog_form"
  (ngSubmit)="submit()"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Refund Description</mat-label>
        <textarea matInput rows="3" formControlName="description" required></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Refund Amount</mat-label>
        <x-currency-input-control
          placeholder="Full order balance"
          [currency]="data.currency"
          formControlName="amount"
        ></x-currency-input-control>
        <mat-hint>Amount to refund, defaults to full paid balance.</mat-hint>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button type="submit" form="wallet_refund_dialog_form" mat-flat-button color="primary">
    Refund
  </button>
</div>
