import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChannelCurrencyItemCollectionProvider,
  UserItemCollectionProvider,
} from '@x/ecommerce/domain-data';

export interface WalletFormDialogData {
  userId?: number | null;
  currency?: string | null;
}

export interface WalletFormDialogResult {
  userId: number;
  currency: string;
}

@Component({
  selector: 'x-wallet-form-dialog',
  templateUrl: './wallet-form-dialog.component.html',
  styleUrls: ['./wallet-form-dialog.component.scss'],
})
export class WalletFormDialogComponent implements OnInit {
  readonly Providers = {
    UserItemCollectionProvider,
    ChannelCurrencyItemCollectionProvider,
  };

  formGroup = new UntypedFormGroup({
    userId: new UntypedFormControl([Validators.required, Validators.maxLength(255)]),
    currency: new UntypedFormControl([Validators.required]),
  });

  constructor(
    private dialogRef: MatDialogRef<WalletFormDialogComponent, WalletFormDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: WalletFormDialogData,
  ) {}

  ngOnInit(): void {
    this.formGroup.reset({
      userId: null,
      currency: null,
    });
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    this.dialogRef.close(this.formGroup.value);
  }
}
