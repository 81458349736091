<h2 mat-dialog-title>Wallet Payment</h2>

<form
  id="wallet-form"
  *ngIf="(loading$ | async) === false"
  (submit)="submit()"
  [formGroup]="debitWalletPaymentForm"
>
  <mat-dialog-content>
    <x-dashboard-form-layout>
      <x-form-panel>
        <mat-form-field>
          <mat-label>Payment note</mat-label>
          <input
            matInput
            type="text"
            formControlName="description"
            required
            autocomplete="disabled"
          />
        </mat-form-field>
      </x-form-panel>
    </x-dashboard-form-layout>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="(loading$ | async) === false" align="end">
    <button mat-flat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button="submit" color="primary" type="submit" form="wallet-form">
      <fa-icon icon="wallet"></fa-icon>
      Pay
    </button>
  </mat-dialog-actions>
</form>

<x-cube-spinner *ngIf="loading$ | async"></x-cube-spinner>
