import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService, WalletTransactionObject } from '@x/ecommerce/domain-client';
import { DebitWalletPaymentInput } from '@x/schemas/ecommerce';
import { BehaviorSubject, Subject } from 'rxjs';

export interface IDebitWalletPaymentDialogData {
  userId: number;
  orderId: number;
}

export interface IDebitWalletPaymentDialogResult {
  transaction?: WalletTransactionObject;
}

@Component({
  selector: 'x-debit-wallet-payment-dialog',
  templateUrl: './debit-wallet-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./debit-wallet-payment-dialog.component.scss'],
})
export class DebitWalletPaymentDialogComponent implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();

  loading$ = new BehaviorSubject(false);

  debitWalletPaymentForm: UntypedFormGroup;

  constructor(
    private walletService: WalletService,
    public dialog: MatDialogRef<IDebitWalletPaymentDialogData, IDebitWalletPaymentDialogResult>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDebitWalletPaymentDialogData,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading$.next(true);

    this.debitWalletPaymentForm = new UntypedFormGroup({
      description: new UntypedFormControl(null, [Validators.required]),
    });

    this.loading$.next(false);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  async submit() {
    this.debitWalletPaymentForm.updateValueAndValidity();
    if (this.debitWalletPaymentForm.invalid) {
      return;
    }

    this.loading$.next(true);

    const { description } = this.debitWalletPaymentForm.value;

    const input: DebitWalletPaymentInput = {
      userId: this.dialogData.userId,
      orderId: this.dialogData.orderId,
      description,
    };

    const transaction = await this.walletService.debitPayment(input).toPromise();

    this.dialog.close({
      transaction,
    });
  }

  cancel() {
    this.dialog.close();
  }
}
