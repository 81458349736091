<h2 mat-dialog-title>Wallet Transaction</h2>

<form
  id="wallet-form"
  *ngIf="(loading$ | async) === false"
  (submit)="submit()"
  [formGroup]="formGroup"
>
  <mat-dialog-content>
    <x-dashboard-form-layout>
      <x-form-panel>
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <x-currency-input-control
            required
            formControlName="amount"
            [currency]="currency"
          ></x-currency-input-control>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Payment note</mat-label>
          <input
            matInput
            type="text"
            formControlName="description"
            required
            autocomplete="disabled"
          />
        </mat-form-field>
      </x-form-panel>
    </x-dashboard-form-layout>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="(loading$ | async) === false" align="end">
    <button mat-flat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-flat-button="submit" color="primary" type="submit" form="wallet-form">
      <fa-icon icon="wallet"></fa-icon>
      Submit
    </button>
  </mat-dialog-actions>
</form>
