import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DebitWalletPaymentDialogComponent,
  IDebitWalletPaymentDialogData,
  IDebitWalletPaymentDialogResult,
} from '../components/debit-wallet-payment-dialog/debit-wallet-payment-dialog.component';
import {
  WalletFormDialogComponent,
  WalletFormDialogData,
  WalletFormDialogResult,
} from '../components/wallet-form-dialog/wallet-form-dialog.component';
import {
  WalletRefundDialogComponent,
  WalletRefundDialogData,
  WalletRefundDialogResult,
} from '../components/wallet-refund-dialog/wallet-refund-dialog.component';
import {
  WalletTransactionFormDialogComponent,
  WalletTransactionFormDialogData,
  WalletTransactionFormDialogResult,
} from '../components/wallet-transaction-form-dialog/wallet-transaction-form-dialog.component';

@Injectable()
export class WalletDialogService {
  constructor(private dialogs: MatDialog) {}

  openDebitPaymentDialog(userId: number, orderId: number) {
    return this.dialogs.open<
      DebitWalletPaymentDialogComponent,
      IDebitWalletPaymentDialogData,
      IDebitWalletPaymentDialogResult
    >(DebitWalletPaymentDialogComponent, {
      width: '500px',
      data: {
        userId,
        orderId,
      },
    });
  }

  openOrderRefundToWalletDialog(data: WalletRefundDialogData) {
    return this.dialogs.open<
      WalletRefundDialogComponent,
      WalletRefundDialogData,
      WalletRefundDialogResult
    >(WalletRefundDialogComponent, { data });
  }

  openCreateWalletDialog() {
    return this.dialogs.open<
      WalletFormDialogComponent,
      WalletFormDialogData,
      WalletFormDialogResult
    >(WalletFormDialogComponent, { autoFocus: false });
  }

  openTransactionWalletDialog(data: WalletTransactionFormDialogData) {
    return this.dialogs.open<
      WalletTransactionFormDialogComponent,
      WalletTransactionFormDialogData,
      WalletTransactionFormDialogResult
    >(WalletTransactionFormDialogComponent, { width: '500px', data });
  }
}
