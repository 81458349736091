<h2 mat-dialog-title>Create Wallet</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="wallet_create_dialog_form"
  (ngSubmit)="submit()"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>User</mat-label>
        <x-data-autocomplete
          formControlName="userId"
          [provider]="Providers.UserItemCollectionProvider"
        ></x-data-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Currency</mat-label>
        <x-data-autocomplete
          formControlName="currency"
          [provider]="Providers.ChannelCurrencyItemCollectionProvider"
        ></x-data-autocomplete>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button type="submit" form="wallet_create_dialog_form" mat-flat-button color="primary">
    Create
  </button>
</div>
