import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface WalletRefundDialogData {
  amount?: number | null;
  currency: string;
}

export interface WalletRefundDialogResult {
  amount?: number | null;
  description: string;
}

@Component({
  selector: 'x-wallet-refund-dialog',
  templateUrl: 'wallet-refund-dialog.component.html',
  styleUrls: ['wallet-refund-dialog.component.scss'],
})
export class WalletRefundDialogComponent {
  formGroup = new UntypedFormGroup({
    description: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
    amount: new UntypedFormControl(this.data.amount, [Validators.min(0)]),
  });

  constructor(
    private dialogRef: MatDialogRef<WalletRefundDialogComponent, WalletRefundDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: WalletRefundDialogData,
  ) {}

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    this.dialogRef.close(this.formGroup.value);
  }
}
